// Breakpoint
$xlg: 1260px;
@mixin xlg {
  @media (max-width: ($xlg)) {
    @content;
  }
}

$lg: 1025px;
@mixin lg {
  @media (min-width: ($lg)) {
    @content;
  }
}

@mixin tb {
  @media (min-width: 768px) and (max-width: 1024px) {
    @content;
  }
}

$md: 1023px;
@mixin md {
  @media (max-width: ($md)) {
    @content;
  }
}

$sm: 767px;
@mixin sm {
  @media (max-width: ($sm)) {
    @content;
  }
}

$xs: 375px;
@mixin xs {
  @media (max-width: ($xs)) {
    @content;
  }
}

// px -> rem変換
$baseFontSize: 10;
@function rem($pixels) {
  @return $pixels / $baseFontSize * 1rem;
}
