.concept {
  padding: 0 12px;
  background-color: $background-color;
  &-wrapper {
    max-width: 920px;
    width: 100%;
    margin: 0 auto;
    padding: 70px 0;
    @include sp {
      width: auto;
      margin: 0 16px;
    }
  }
  &-recommend {
    padding-top: 70px;
    &__title {
      text-align: center;
      font-size: rem(24);
      font-weight: $font-bold;
      margin-bottom: 24px;
    }
    &__content {
      max-width: 656px;
      width: 100%;
      margin: 0 auto;
      background-color: $white;
      display: grid;
      grid-template-columns: 1fr 2fr;
      gap: 48px;
      padding: 24px;
      @include sp {
        width: auto;
        grid-template-columns: 1fr;
      }
    }
    &__illust {
      width: 140px;
      margin: auto;
    }
    &__item {
      padding-left: 14px;
      &::before {
        content: "";
        width: 3px;
        height: 3px;
        display: inline-block;
        border-radius: 100%;
        background: $black;
        position: relative;
        left: -14px;
        top: -3px;
      }
    }
  }
  &-price {
    max-width: 656px;
    width: 100%;
    margin: 0 auto;
    background-color: $white;
    padding: 24px;
    @include sp {
      width: auto;
    }
    &-primary {
      color: $pink;
      font-size: rem(32);
      font-weight: bold;
      text-align: center;
      display: block;
    }
    &-secondary {
      font-size: rem(12);
    }
  }
}
