.form {
  &-size {
    margin-top: 16px;
  }
  &-label {
    display: block;
    margin-bottom: 16px;
    color: $black;
    font-weight: 400;
    font-size: rem(12);
  }
  &-icon {
    &__required {
      color: $pink;
      font-weight: $font-bold;
      margin-right: 4px;
    }
    &__optional {
      margin-right: 4px;
      font-weight: $font-bold;
    }
  }
  &-input {
    &__text {
      width: 100%;
      padding: 8px 4px;
      border: 0.5px solid $gray;
      background-color: $white;
    }
    &__textarea {
      width: 100%;
      height: 80px;
      padding: 8px 4px;
      border: 0.5px solid $gray;
      background-color: $white;
    }
  }
  &-button {
    margin: 0 auto;
    padding: 12px 74px;
    margin-top: 32px;
  }
}

// CF7のフォームレイアウト調整

.wpcf7-form-control {
  padding: 12px 8px;
  border-radius: 8px;
  &-wrap {
    margin-top: 8px;
  }
}
