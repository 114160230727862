.kv {
  font-weight: $font-bold;
  background-color: $background-color;
  &-container {
    max-width: 870px;
    margin: 0 auto;
    padding: 56px 40px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 36px;
    align-items: center;
    @include sp {
      width: auto;
      grid-template-columns: 1fr;
      padding: 56px 16px;
    }
  }
  &-copy {
    &_text {
      font-size: rem(36);
      line-height: 1.2;
      @include sp {
        font-size: rem(24);
      }
    }
    &_em {
      color: $pink;
      font-size: rem(84);
      @include sp {
        font-size: rem(64);
      }
    }
    &_subtext {
      margin-top: 6px;
    }
  }
  &-illust {
    &_img {
      max-width: 100%;
      margin: 0 auto;
      display: block;
    }
  }
  &-cta {
    max-width: 100%;
    width: 290px;
    margin-right: auto;
    margin-top: 14px;
  }
}
