.layout-wrapper {
  display: flex;
  @include md {
    flex-direction: column;
  }

  &--form {
    @extend .layout-wrapper;
    max-width: rem(1200);
    margin: 0 auto;

    @include md {
      flex-direction: row;
    }

    @include sm {
      flex-direction: column;
    }
  }
}

.layout-wrapper--contact {
  max-width: rem(1036);
  margin: 0 auto;
}

.layout-main {
  position: relative;
  flex: 1 1 auto;
}

.layout-side {
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  max-width: 28rem;
  background-color: $background-color02;

  @include md {
    display: none;
  }

  &--form {
    @extend .layout-side;
    max-width: 380px;

    @include md {
      display: flex;
    }

    @include sm {
      max-width: 100%;
    }
  }

  &__contents {
    position: sticky;
    top: 0;
    width: 100%;
    padding: 20px 12px;
    max-width: rem(340);
    overflow-y: auto;
    height: 100vh;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 6px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 6px;
    }

    &--form {
      width: 100%;
      padding: 40px 62px 56px;
      margin-bottom: 6px;
      height: auto;
      border-radius: rem(8);
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);

      @include sm {
        max-width: 100%;
        margin-left: 0;
        padding: 0 24px 48px;
        box-shadow: none;
      }
    }
  }
}
