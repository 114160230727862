.button-primary {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  text-decoration: none;
  transition: 0.3s;
  color: $pink;
  background-color: $light-pink;
  border: 2px solid $pink;
  border-radius: 8px;

  &:hover {
    background-color: $white;
  }
  &--small {
    @extend .button-primary;
    font-size: rem(14);
    padding: 8px 24px;

    @include sm {
      font-size: rem(10);
      padding: 8px 16px;
    }
  }

  &--large {
    @extend .button-primary;
    font-size: rem(18);
    padding: 12px 24px;
  }
}

.button-secondary {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  text-decoration: none;
  transition: 0.3s;
  color: $pink;
  background-color: $white;
  border: 2px solid $pink;
  border-radius: 8px;

  &:hover {
    color: $white;
    background-color: $pink;
  }
  &--small {
    @extend .button-secondary;
    font-size: rem(14);
    padding: 8px 24px;

    @include sm {
      font-size: rem(10);
      padding: 8px 16px;
    }
  }

  &--large {
    @extend .button-secondary;
    font-size: rem(18);
    padding: 12px 24px;
  }
}
