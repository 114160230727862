.ctaform {
  font-weight: $font-bold;
  padding: 40px 0;
  background-color: $light-pink;
  @include sp {
    width: auto;
    padding: 40px 16px;
  }
  &-wrapper {
    max-width: 556px;
    width: 100%;
    margin: auto;
  }
  &-title {
    font-size: rem(32);
    text-align: center;
    margin-bottom: 12px;
    @include sp {
      font-size: rem(24);
    }
  }
  &-description {
    margin-bottom: 36px;
    &--em {
      font-size: rem(36);
    }
  }
  &-wpform {
    padding: 30px;
    background-color: $white;
    border-radius: 8px;
    margin-top: 16px;
  }
  &__illust {
    width: 300px;
    margin: auto;
  }
}
