@charset "utf-8";
/*
Theme Name: tsunagari-sns
Description: tsunagari-snsテーマ
*/

/* reset, base */
@import url("https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,700&display=swap&subset=japanese");
@import "variables";
@import "utility";
@import "reset";
@import "base";
@import "mixins";

/* components */
@import "components/header";
@import "components/layout";
@import "components/kv";
@import "components/button";
@import "components/concept";
@import "components/heading";
@import "components/features";
@import "components/flow";
@import "components/cta";
@import "components/contents";
@import "components/message";
@import "components/member";
@import "components/faq";
@import "components/ctaform";
@import "components/form";
@import "components/footer";
