//=============================================================================
//
//  mixin, functions
//
//=============================================================================
// ------------------------------------------------------
// mediaquery
// ------------------------------------------------------

// $xx-small: 375px;
// $x-small: 480px;
// $small: 768px;
// $medium: 960px;
// $large: 1160px;

@mixin media($break-point) {
  @media screen and (min-width: $break-point) {
    @content;
  }
}

@mixin sp {
  @media screen and (max-width: $medium) {
    @content;
  }
}

@mixin x-small {
  @media screen and (min-width: $x-small) and (max-width: $small) {
    @content;
  }
}

@mixin small {
  @media screen and (min-width: $small) and (max-width: $medium) {
    @content;
  }
}

@mixin medium {
  @media screen and (min-width: $medium) and (max-width: $large) {
    @content;
  }
}

@mixin large {
  @media screen and (min-width: $large) {
    @content;
  }
}

@mixin hide-text() {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

// ------------------------------------------------------
// utility
// ------------------------------------------------------
@mixin clearfix_ {
  &:before {
    clear: both;
    content: "";
    display: table;
  }

  &:after {
    clear: both;
    content: "";
    display: table;
  }
}

.sp-none {
  @include sp {
    display: none;
  }
}

.sp-block {
  display: none;
  @include sp {
    display: block;
  }
}

.inline-block {
  display: inline-block;
}
