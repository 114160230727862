// width
$contents-width: 1160px;
$main-width: 765px;
$sidebar-width: 395px;

$xx-small: 375px;
$x-small: 480px;
$small: 769px;
$medium: 960px;
$large: 1160px;

// Font Sizes
$font-size-4x: 50;
$font-size-3x: 40;
$font-size-2x: 32;
$font-size-1_5x: 24;
$font-size-x: 16;
$font-size-base: 14;
$font-size-s: 12;
$font-size-2s: 11;
$font-size-3s: 10;

$font-bold: bold;

$white: #ffffff;
$black: #222222;
$pink: #ff69b4;
$light-pink: #FFF0F5;
$gray: #e1e1e1;
$accent-color: #ff532e;
$background-color: #fffdf5;
$background-color02: #f8f8f8;

$color-gray-1: #c9c9c9;

$font-hiragino: "Hiragino Kaku Gothic Pro", Meiryo, Osaka, "MS PGothic", sans-serif;
$font-lato: "Lato", "Hiragino Kaku Gothic Pro", Meiryo, sans-serif;
$font-noto-sans: "Noto Sans JP", "Hiragino Kaku Gothic Pro", YuGothic, Meiryo, Osaka, "MS PGothic",
  sans-serif;
