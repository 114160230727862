.contents {
  max-width: 920px;
  width: auto;
  margin: 0 auto;
  padding: 70px 12px;
  @include sp {
    margin: 0 16px;
  }
  &-wrapper {
    margin-top: 56px;
  }
  &-list {
    margin-bottom: 40px;
    &__title {
      font-size: rem(24);
      margin-bottom: 16px;
      font-weight: $font-bold;
      @include sp {
        font-size: rem(18);
      }
    }
  }
}
