.cta {
  font-weight: $font-bold;
  padding: 40px 0;
  background-color: $background-color;
  &-wrapper {
    max-width: 540px;
    width: 100%;
    margin: auto;
    @include sp {
      width: auto;
      padding: 0 16px;
    }
  }
  &-title {
    font-size: rem(48);
    color: $white;
    margin-bottom: 48px;
    text-align: center;
    @include sp {
      font-size: rem(32);
    }
  }
  &-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 36px;
    @include sp {
      display: block;
      width: auto;
    }
    &__illust {
      width: 170px;
      margin: auto;
      @include sp {
        margin-bottom: 12px;
      }
    }
    &__description {
      &--em {
        font-size: rem(36);
        color: $pink;
      }
    }
  }
  &-button {
    margin-top: 18px;
  }
}
