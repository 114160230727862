.header {
  position: sticky;
  top: 0;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 24px;
  background-color: $white;

  @include sp {
    padding: 8px 16px;
  }
}

.header-title {
  &__logo {
    width: rem(80);

    @include small {
      width: rem(60);
    }
  }
}

.header-nav {
  display: flex;
  align-items: center;
  flex-shrink: 0;

  @include sp {
    position: absolute;
    top: 93px;
    left: -1000px;
    transition: 0.3s;
  }

  &--open {
    background-color: $white;
    @include sp {
      display: flex;
      flex-direction: column;
      left: 0;
      width: 100%;
      height: 100vh;
    }

    @include small {
      top: 79px;
    }
  }

  &__list {
    display: flex;
    padding-right: 8px;

    @include sp {
      flex-direction: column;
      width: 100%;
      padding: 32px 24px;
    }
  }

  &__item {
    &:not(:first-of-type) {
      border-left: 1px solid $color-gray-1;

      @include sp {
        border-left: none;
      }
    }

    @include sp {
      border-bottom: 1px solid $color-gray-1;

      &:first-of-type {
        border-top: 1px solid $color-gray-1;
      }
    }
  }

  &__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
    color: $black;
    text-decoration: none;
    transition: 0.3s;

    @include sp {
      font-size: rem(16);
      padding: 16px;
    }
  }

  &__action {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__button {
    .header-nav__action & {
      @include sp {
        font-size: rem(14);
        padding: 12px 24px;
      }
    }
  }

  &__text-link {
    margin-top: 8px;
    font-size: rem(10);
    font-weight: $font-bold;
    transition: 0.3s;

    &:hover {
      color: $black;
    }

    .header-nav__action & {
      @include sp {
        font-size: rem(12);
      }
    }
  }
  &__sp-text {
    display: none;

    @include sp {
      display: inline;
    }
  }
}

.header-nav-sp {
  display: none;
  @include sp {
    display: flex;
    align-items: center;
  }

  &__action {
    margin-right: 8px;
  }
}

.header-menu-button {
  position: relative;
  padding: 0;
  width: rem(24);
  height: rem(20);
  background-color: transparent;

  &__inner {
    display: block;
    width: rem(24);
    height: 2px;
    background-color: $black;

    .header-menu-button--open & {
      background-color: transparent;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: rem(24);
      height: 2px;
      background-color: $black;
      transition: 0.3s;

      .header-menu-button--open & {
        transform: rotate(45deg);
        top: 9px;
      }
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      width: rem(24);
      height: 2px;
      background-color: $black;
      transition: 0.3s;

      .header-menu-button--open & {
        transform: rotate(-45deg);
        bottom: 9px;
      }
    }
  }
}

.header-no-navmenu {
  margin: 20px 36px 40px;
  @include sp {
    margin: 8px 16px 20px;
  }
}
