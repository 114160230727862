.member {
  max-width: 920px;
  width: auto;
  margin: 0 auto;
  padding: 70px 12px;
  &-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 36px;
    @include sp {
      grid-template-columns: 1fr;
    }
  }
  &-item {
    padding: 20px;
    background-color: $background-color;
    &__img {
      width: 260px;
      display: block;
      margin: auto;
    }
    &__title {
      font-size: rem(24);
      font-weight: $font-bold;
      margin-bottom: 16px;
      @include sp {
        font-size: rem(16);
      }
    }
    &__message {
      font-size: rem(18);
      margin-top: 8px;
    }
  }
}
