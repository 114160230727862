.flow {
  max-width: 920px;
  width: auto;
  margin: 0 auto;
  padding: 70px 12px;
  &-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 36px;
    @include sp {
      width: auto;
      margin: 0 16px;
      display: block;
    }
    &__item {
      padding: 16px;
      background-color: $background-color;
      @include sp {
        margin-bottom: 16px;
      }
    }
    &__step {
      color: $pink;
      font-weight: $font-bold;
    }
    &__title {
      font-size: rem(22);
      font-weight: $font-bold;
      margin-bottom: 18px;
      @include sp {
        font-size: rem(20);
      }
    }
  }
}
