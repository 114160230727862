.features {
  max-width: 920px;
  width: 100%;
  margin: 0 auto;
  padding: 70px 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 28px;
  @include sp {
    grid-template-columns: 1fr;
    width: auto;
    margin: 0 16px;
  }
  &-list {
    &__dt {
      margin-bottom: 62px;
      text-align: center;
      position: relative;
      min-height: 78px;
      display: flex;
      align-items: center;
      justify-content: center;
      @include sp {
        min-height: auto;
        margin-bottom: 36px;
      }
      &::after {
        position: absolute;
        bottom: -22px;
        left: 0;
        right: 0;
        margin: auto;
        width: 100px;
        height: 3px;
        content: "";
        background: $light-pink;
      }
    }
    &__title {
      font-size: rem(24);
      font-weight: $font-bold;
      @include sp {
        font-size: rem(18);
      }
    }
  }
}
