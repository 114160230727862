.heading-section {
  margin-bottom: 60px;
  text-align: center;
  position: relative;
  &::after {
    position: absolute;
    bottom: -22px;
    left: 0;
    right: 0;
    margin: auto;
    width: 100px;
    height: 3px;
    content: "";
    background: $pink;
  }
  &__title {
    font-size: rem(36);
    font-weight: bold;
    @include sp {
      font-size: rem(26);
    }
  }
}

.heading-em {
  display: block;
  text-align: center;

  &::before {
    content: "＼";
  }
  &::after {
    content: "／";
  }
}
