html {
  font-size: 62.5%;
}

body {
  box-sizing: border-box;
  font-family: $font-noto-sans;
  font-size: 1.4rem;
  line-height: 1.6;
  position: relative;
  overflow-wrap: break-word;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include media($small) {
    font-size: 1.6rem;
  }
}

a {
  text-decoration: none;

  &:focus {
    outline: none;
  }
}

* {
  -webkit-tap-highlight-color: rgba(#69d6ff, 0.5);
}

img {
  -webkit-tap-highlight-color: transparent;
}

::selection {
  background-color: rgba(#69d6ff, 0.5);
}

img {
  border: none;
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}

main {
  display: block;
}

//reset button
button {
  appearance: none;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  outline: none;
  padding: 0;
}

input,
textarea {
  -webkit-appearance: none; //safari上部の影

  &:focus {
    outline: none;
  }
}

strong {
  font-weight: bold;
}
