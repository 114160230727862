.message {
  max-width: 920px;
  width: auto;
  margin: 0 auto;
  padding: 70px 12px;
  &-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 36px;
    @include sp {
      grid-template-columns: 1fr;
      margin: 0 16px;
    }
  }
  &-contents {
    &__block {
      margin-bottom: 36px;
    }
    &__title {
      font-size: rem(24);
      font-weight: $font-bold;
      @include sp {
        font-size: rem(16);
      }
    }
    &__insta {
      display: grid;
      grid-template-columns: 88px 1fr;
      align-items: center;
    }
  }
  &-list {
    display: flex;
    &__dt {
      flex: 1;
    }
    &__dd {
      flex: 4;
    }
  }
}
