.faq {
  max-width: 920px;
  width: auto;
  margin: 0 auto;
  padding: 70px 12px;
  @include sp {
    margin: 0 16px;
    width: auto;
  }
  &-item {
    font-weight: $font-bold;
    margin-bottom: 18px;
    &__question {
      cursor: pointer;
      position: relative;
      margin-bottom: 16px;
      @include sp {
        margin-right: 16px;
      }
    }
    &__answer {
      display: none;
      padding: 16px;
      background-color: $light-pink;
    }
    &__arrow {
      content: "";
      position: absolute;
      right: 30px;
      top: calc(50% - 20px);
      width: 0;
      height: 0;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      @include sp {
        top: calc(50% - 10px);
        right: 0;
        left: 100%;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
      }
      &--top {
        @extend .faq-item__arrow;
        border-top: 18px solid $pink;
        @include sp {
          border-top: 12px solid $pink;
        }
      }
      &--bottom {
        @extend .faq-item__arrow;
        border-bottom: 18px solid $pink;
        @include sp {
          border-bottom: 12px solid $pink;
        }
      }
    }
  }
}
